// =======================
//  GENERAL
// =======================



html, body {

	background-color: var(--color-dark);
	color: var(--color-gray-500);

	font-family: 'ClashGrotesk', Arial, Helvetica, sans-serif;
	letter-spacing: 0.02em;

	min-height: 100vh;
}

body {
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

	min-height: 100vh;
}

::placeholder {
	color: var(--color-gray-250);
	opacity: 1; /* Firefox */
}
  
::-ms-input-placeholder { /* Edge 12 -18 */
	color: var(--color-gray-250);
}

.error-page {
	
	padding-top: var(--spacing-section-xl);
	padding-bottom: var(--spacing-section-xl);
	
	h1, h1 > * {
		text-align: center;
	}
	
	.container {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		gap: var(--spacing-48);
	}
	
	.btn {
		justify-content: center;
		
		margin-top: var(--spacing-48);
	}
	
	&-content {
		grid-column: 3 / span 8;

		display: flex;
		flex-direction: column;
		align-items: center;
		gap: var(--spacing-48);
	}
}

.legal-page {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: var(--spacing-48);

	.container {
		grid-column: 3 / span 8;
		
		@media screen and (max-width: 1024px) {
			grid-column: 2 / span 10;
		}

		@media screen and (max-width: 767px) {
			grid-column: 1 / span 12;
		}
	}

	&-content {
		padding-top: var(--spacing-section-xs);
		padding-bottom: var(--spacing-section-xs);

		
		font-size: clamp(1rem, 1.25vw, 1.5rem);
		line-height: clamp(1.5rem, 1.875vw, 2.25rem);
		letter-spacing: 0.02em;
	}

	.dates {
		margin-bottom: var(--spacing-64);
	}

	.callout {
		background: var(--color-gray-50);
		margin: var(--spacing-16) 0;
		padding: var(--spacing-32) var(--spacing-48);
		border-radius: 1rem;
	}

	h3 {
		margin-top: var(--spacing-64);
		margin-bottom: var(--spacing-16);
		color: var(--color-white);
	}
	p {
		margin-top: 0;
		margin-bottom: var(--spacing-16);
	}
}

.sticky-container {
	min-height: 100vh;
}

.sticky-footer-wrapper {
	display: grid;
	grid-template-rows: 1fr auto;

	min-height: 100vh;
}

::selection {
	color: var(--color-dark);
	background: var(--color-accent);
  }

.layout-content {
	padding-top: var(--spacing-section-sm);
}

.layout-content-error {
	align-self: center;
}

li, ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

a {
    color: var(--color-white);
    text-decoration: none;

	-webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:hover, a:focus {
	color: var(--color-accent);
    text-decoration: none;
}

a.link {
	color: var(--color-white);
	text-decoration: none;

	&:hover, &:focus {
		color: var(--color-accent);
    	text-decoration: underline;
	}
}

button {
	-webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	font-weight: 400;
}



.mobile-flex {
    display: none !important;
}

@media screen and (max-width: 767px) {
    .mobile-hide {
        display: none !important;
    }
    .mobile-flex {
        display: flex !important;
    }
}


.tablet-show, .tablet-flex {
    display: none !important;
}

@media screen and (max-width: 1024px) {
    .tablet-hide {
        display: none !important;
    }
    .tablet-flex {
        display: flex !important;
    }

	.tablet-show {
        display: block !important;
    }
}






h1 {
	font-size: clamp(5.25rem, 7vw, 10.5rem);
	line-height: clamp(6rem, 8vw, 12rem);

	color: var(--color-white);

	.italic	{
		display: block;

		font-family: 'KristolitItalic', serif;
		font-style: italic;

		color: var(--color-accent);
	}

	.uppercase {
		display: block;
		
		font-size: clamp(5rem, 6.66vw, 10rem);
		font-family: 'ClashDisplay', sans-serif;
		text-transform: uppercase;
	}

	@media screen and (max-width: 767px) {
		font-size: min(17vw, 10.5rem);
		line-height: min(19vw, 10.5rem);

		.uppercase {
			font-size: min(16vw, 10rem);
		}
	}
}

h1.on-page {
	font-size: clamp(3.125rem, 4.689vw, 6.25rem);
	line-height: clamp(3.75rem, 5.625vw, 7.5rem);

	.uppercase {
		font-size: clamp(3rem, 4.5vw, 6rem);
	}

	@media screen and (max-width: 767px) {
		font-size: min(8.8vw, 2.75rem);
		line-height: min(11.2vw, 3.5rem);
		
		.uppercase {
			font-size: min(8.4vw, 2.625rem);
		}
	}
}


h2 {
	// font-size: clamp(3rem, 5vw, 6rem);
	// line-height: clamp(3.5rem, 5.83vw, 7rem);

	font-size: clamp(3.125rem, 4.689vw, 6.25rem);
	line-height: clamp(3.75rem, 5.625vw, 7.5rem);

	text-transform: uppercase;

	color: var(--color-white);

	&.small-caps {
		font-size: clamp(2.25rem, 3.618vw, 4.5rem);
		line-height: clamp(3.75rem, 6.03vw, 7.5rem);
	}

	.italic	{
		font-family: 'KristolitItalic', serif;
		font-style: italic;
		text-transform: none;

		color: var(--color-accent);
	}

	@media screen and (max-width: 767px) {
		font-size: min(8.8vw, 2.75rem);
		line-height: min(11.2vw, 3.5rem);
		
		.uppercase {
			font-size: min(8.4vw, 2.625rem);
		}
	}
}

h3 {
	font-size: clamp(1.5rem, 2.5vw, 3rem);
	line-height: clamp(2rem, 3.33vw, 4rem);
	letter-spacing: 0.01em;
	font-weight: 300;
	
	
	.italic {
		font-family: 'KristolitItalic', serif;
		font-style: italic;
		text-transform: none;
		
		color: var(--color-white);
	}
	
	@media screen and (max-width: 767px) {
		font-size: min(6vw, 1.875rem);
		line-height: min(8vw, 2.5rem);
	}
}

h3.small-caps {
	font-size: clamp(1.53125rem, 2.55vw, 3.0625rem);
	line-height: clamp(2.5rem, 4.16vw, 5rem);
	font-weight: 400;
	text-transform: uppercase;
	
	color: var(--color-white);
	
	.italic {
		font-size: clamp(1.875rem, 3.125vw, 3.75rem);
		font-style: italic;
		letter-spacing: 0em;

		color: var(--color-accent);
	}
	@media screen and (max-width: 767px) {
		font-size: clamp(1.2rem, 4.8vw, 1.5rem);
		line-height: clamp(2rem, 8vw, 2.5rem);
		
		.italic {
			font-size: clamp(1.125rem, 6vw, 1.875rem);
		}
	}
}

h4 {
	font-size: clamp(1.35rem, 1.875vw, 2.25rem);
	line-height: clamp(1.5rem, 2.083vw, 2.5rem);
	
	color: var(--color-white);
}

h4.uppercase {
	font-family: 'ClashDisplay', sans-serif;
	letter-spacing: 0.02em;
	text-transform: uppercase;

	.italic {
		font-family: 'KristolitItalic', serif;
		font-style: italic;
		text-transform: none;
		color: var(--color-accent);
	}
}

.container {
	max-width: 120rem;
	margin: 0 auto;
	padding-left: var(--page-margins);
	padding-right: var(--page-margins);
}

.btn {
	display: flex;
	align-items: center;
	
	text-decoration: none;
	color: var(--color-white);
	border: 0;

	&.btn-large {
		padding: var(--spacing-24) var(--spacing-32);

		font-size: clamp(1rem, 1.04vw, 1.25rem);
		line-height: clamp(1.2rem, 1.25vw, 1.5rem);
	}

	&.btn-xlarge {
		padding: var(--spacing-32) var(--spacing-64);

		font-size: clamp(1rem, 1.04vw, 1.25rem);
		line-height: clamp(1.2rem, 1.25vw, 1.5rem);
		border-radius: 5rem;
	}
}

.btn-outline {
	padding: var(--spacing-16) var(--spacing-32);
	border: 1px solid var(--color-gray-250);
	border-radius: 4rem;

	&.with-icon {
		display: flex;
		align-items: center;
		gap: var(--spacing-8);
		padding-right: var(--spacing-24);

		.icon {
			display: flex;
			align-items: center;
		}
	}

	&.active, &:hover, &:focus {
		color: var(--color-dark);
		background: var(--color-accent);
		border-color: var(--color-accent);
	}
}

.btn-toggle {
	padding: var(--spacing-16) var(--spacing-32);
	overflow: visible;
	// border: 1px solid var(--color-gray-250);
	// border-radius: 4rem;

	&.active, &:hover, &:focus {
		color: var(--color-dark);
		// background: var(--color-accent);
		border-color: var(--color-gray-150);
	}
}

.btn-primary {
	padding: var(--spacing-24) var(--spacing-32);
	
	font-weight: 500;
	
	border-radius: 2rem;
	background-color: var(--color-accent);
	color: var(--color-dark);

	&.with-icon {
		display: flex;
		align-items: center;
		gap: var(--spacing-24);
	}

	&:hover, &:focus {
		color: var(--color-dark);
	}
}


// BTN animations
.btn {
	overflow: hidden;
}


[data-magnetic] span,
[data-magnetic-nav] span {
	display: flex;
	align-items: center;
	gap: var(--spacing-8);
}


 
.nav-offcanvas-item {
	overflow: hidden;
}

.nav-item a, .nav-offcanvas-item a, 
.nav-item span, .nav-offcanvas-item span {
	display: block;
	position: relative;
}

.nav-item > a {

	padding: var(--spacing-8) var(--spacing-16);
	border-radius: 2rem;

	&:focus {
		color: var(--color-white);
	}
	
	&.active, &:hover {
		background-color: var(--color-accent);
		color: var(--color-dark);
	}
}

.nav-offcanvas-item > a {
	overflow: hidden;
}


@media (hover: hover) {
	.nav-offcanvas-item > a > span {
		overflow: hidden;
		animation: MoveUpOutStart 0.2s forwards, MoveUpOutEnd 0.2s forwards 0.2s;
	}

	.nav-offcanvas-item:hover > a > span {
		animation: MoveUpInStart 0.2s forwards, MoveUpInEnd 0.2s forwards 0.2s;
	}
}


@keyframes MoveUpInStart {
	from {
		transform: translate3d(0,0,0);
	}
	to {
		transform: translate3d(0,-105%,0);
	}
}

@keyframes MoveUpInEnd {
	from {
		transform: translate3d(0,100%,0);
	}
	to {
		transform: translate3d(0,0,0);
	}
}

@keyframes MoveUpOutStart {
	from {
		transform: translate3d(0,0,0);
	}
	to {
		transform: translate3d(0,100%,0);
	}
}

@keyframes MoveUpOutEnd {
	from {
		transform: translate3d(0,-105%,0);
	}
	to {
		transform: translate3d(0,0,0);
	}
}






.parallax-wrap {
	overflow: hidden;
	border-radius: 1rem;
}

.parallax-image {
	transform: scale(1.1);
}


.hover-zoom-img img {
	transition: transform .25s ease-out;
}

.hover-zoom-img:hover img {
	transform: scale(1.2) !important;
}





.divider {
	position: relative;
	height: 1px;

	.box {
		height: 2.5rem;
		width: 100%;
		display: flex;
		position: relative;
		top: -1.25rem;
		z-index: 1;

		&:hover {
			height: 7.5rem;
			top: -3.75rem;
		}
	}

	svg {
		position: absolute;
		top: -5rem;
		left: 0;
		right: 0;
		width: 100%;
		height: 10rem;
		pointer-events: none;
		
		path {
			fill: none;
			stroke: var(--color-gray-250);
			stroke-width: 1;
		}
	}
}

.tags-row {
	display: flex;
	gap: var(--spacing-24);
}

.tag {
	padding: var(--spacing-16) var(--spacing-24);

	font-size: 0.875rem;
	line-height: 1.5rem;

	color: var(--color-white);

	border: 1px solid var(--color-gray-250);
	border-radius: 5rem;
}







.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 300;
    pointer-events: none;
    background: transparent;
}

.loading-screen {
    transform: trnaslateY(0);
    
	display: flex;
	justify-content: center;
	align-items: center;

	padding: 0;
    width: 100%;
    height: 100%;
	
    background: var(--color-accent);

	pointer-events: all;
	
	clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    will-change: top;


	.page-title-wrap {
		overflow: hidden;

		padding-left: var(--page-margins);
		padding-right: var(--page-margins);
	}

	.page-title {
		font-size: min(3.5vw, 3.5rem);
		line-height: min(4vw, 4rem);
		text-align: center;

		transform: translateY(100%);

		color: var(--color-dark);

		@media screen and (max-width: 1024px) {
			font-size: min(4.66vw, 3.5rem);
			line-height: min(5.33vw, 4rem);
		}

		@media screen and (max-width: 767px) {
			font-size: min(5.6vw, 3.5rem);
			line-height: min(6.4vw, 4rem);
		}
	}
}






.intro-container {
	position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 300;
    pointer-events: none;
    background: transparent;
}

.intro-screen {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

	width: 100%;
    height: 100%;

	font-size: min(3.33vw, 4rem);
	line-height: min(5vw, 6rem);

	color: var(--color-dark);
    background: var(--color-accent);

	clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    will-change: clip-path;
	
    overflow: hidden;
    z-index: 400;
}



.intro-screen .page-title-wrap {
    position: relative;
	
	padding-left: var(--page-margins);
	padding-right: var(--page-margins);

	text-align: center;
    overflow: hidden;
}

.intro-screen .page-title {
	transform: translateY(100px);

	font-size: clamp(2rem, 3.33vw, 4rem);
	line-height: clamp(2.4rem, 4vw, 4.8rem);
	text-align: center;
}

.intro-screen .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
	opacity: 50%;

    background: var(--color-accent);
    transform-origin: right top;
    transform: scaleX(1) rotate(0.001deg);
}


.word-wrap {
	display: inline-block;
	clip-path: polygon(-5% 0%, 105% 0%, 105% 110%, -5% 110%);
}

.word {
	will-change: transform;
}


.line-wrap {
	clip-path: polygon(-5% 0%, 105% 0%, 105% 110%, -5% 110%);
}

.line {
	will-change: transform;
}






// =======================
//  CURSOR
// =======================

.mf-cursor {
	z-index: 260;

	@media (hover: hover) {
		display: block;
	}

	@media (hover: none) {
		display: none;
	}


	&:before {
		background: var(--color-accent);
	}
	@supports (mix-blend-mode:difference) {
		mix-blend-mode:difference;

		&:before {
			background: #dbff57;
		}
	}

	&.-normal-blend {
		mix-blend-mode: normal;

		&:before {
			background: var(--color-accent);
		}
	}

	&.-text {
		
        &:before {
			opacity:1;
            transform:scale(2.5);	
        }

        &.-active:before {
            transform:scale(1.6);
            transition-duration:0.2s;
        }
    }
	&.-nav-zoom {
		&:before {
			background: var(--color-accent);
			opacity: .1;
		}
    }
	&.-inverse {
		&:before {
        	background: var(--color-dark);
		}
    }
	&.-zoom {
        &:before {
            transform:scale(1.5);
        }
    }
	&-text {
		color: var(--color-dark);
	}

	&.-next {
		&:before {
			
			transform:scale(2);
		}
	} 

	.mf-cursor-media {
		width: 3rem;
		height: 3rem;
		margin: -1.5rem 0 0 -1.5rem;
	}

}










.navbar {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 275;

	@media screen and (max-width: 1024px) {
		z-index: 200;
	}
}

.nav-main {
	display: grid;
	grid-template-columns: 1fr 3fr 1fr;
	justify-content: center;
	align-items: center;

	padding: var(--spacing-64) var(--spacing-64) 0;

	@media screen and (max-width: 1024px) {
		padding: var(--spacing-64) var(--page-margins) 0;
	}
}

.navbar ul {
	list-style-type: none;
	padding: 0;
	margin: 0;

	display: flex;
	gap: var(--spacing-48);
	justify-content: center;
}

.nav-main-center {
	overflow: hidden;
}

.navbar {
	pointer-events: none;

	.nav-main-right {
		pointer-events: all;
	}
}

.navbar.opened {
	pointer-events: all;
}

.nav-main-center.overflow-visible {
	overflow: visible;
}

.nav-main-right {
	display: flex;
	justify-content: flex-end;
}

.logo {
	display: flex;
	gap: 0.04em;

	margin: clamp(0.5rem, 1vw, 1rem) 0;

	font-size: 1.5rem;

	mix-blend-mode: difference;
	overflow: hidden;
	
	.name {
		color: var(--color-gray-500);
		text-transform: uppercase;
		letter-spacing: 0.04em;
	}
	.surname {
		color: var(--color-white);
		text-transform: uppercase;
		letter-spacing: 0.04em;
	}
	.domain {
		color: var(--color-gray-500);
		font-family: 'KristolitItalic', serif;
		font-style: italic;

	}
}

.contact-me-btn {
	transform-origin: center right;
}

.navbar.theme-dark {
	color: var(--color-dark);

	.surname {
		color: var(--color-dark);
	}

	.btn {
		color: var(--color-dark);
	}

	svg {
		fill: var(--color-dark);
	}
}


.nav-toggle {
	position: fixed;
	z-index: 275;
	
	top: var(--spacing-64);
	right: var(--spacing-64);

	pointer-events: none;
	overflow: visible;

	height: clamp(1.2rem, 1.25vw, 1.5rem);

	@media screen and (max-width: 1024px) {
		right: calc(var(--page-margins) - var(--spacing-32));
	}

	&.hidden {
		visibility: hidden;
		opacity: 0;
	}

	.burger {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		gap: 0.4125rem;
	}

	.mag-wrapper {
		position: absolute;
		top: -20px;
		right: -20px;
		left: -20px;
		bottom: -20px;
		
		cursor: pointer;
		pointer-events: all;
	}

	&:hover .mag-wrapper {
		top: -80px;
		right: -80px;
		left: -80px;
		bottom: -80px;
	}

	input {
		visibility: hidden;
		position: absolute;
		width: 0;
		height: 0;
	}

	span {
		width: 1.5rem;
		height: 0.10625rem;
		background-color: var(--color-white);

		transform: translateY(0) rotate(0);
		transition: all .2s ease-in-out;
	}

	@media (hover: hover) {
		&:hover span {
			background-color: var(--color-dark);	
		}
	
		&.active:hover span {
			background-color: var(--color-accent);	
		}
	}

	
	&.theme-dark span {
		background-color: var(--color-dark);
	}




	&.active {
		span:nth-of-type(1) {
			transform: translateY((0.4125rem/2) + 0.0625rem) rotate(45deg);
		}
		span:nth-of-type(2) {
			transform: translateY((-(0.4125rem/2) - 0.0625rem)) rotate(-45deg);
		}
	}
}

.nav-offcanvas {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;

	visibility: hidden;
	opacity: 0;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	
	width: clamp(30rem, 30vw, 40rem);
	max-width: 100%;
	padding: var(--spacing-96) var(--spacing-section-sm);

	clip-path: polygon(0% 0%,100% 0%,100% 100%,0% 100%);
	
	color: var(--color-dark);

	transform-origin: center right;
	overflow: hidden;

	z-index: 250;

	.bg-layer {
		position: absolute;
		right: 0; top: 0;

		transform: scaleX(0);
		transform-origin: center right;
		
		width: 100%;
		height: 100%;
		z-index: -20;
	}
	.bg-layer-01 {
		background-color: var(--color-accent);
	}

	a {
		color: var(--color-dark);
	}

	.label {
		opacity: .5;
		font-size: 1rem;
	}

	&-center {
		display: flex;
		flex-direction: column;
		gap: var(--spacing-64);
	}

	.nav-offcanvas-items {
		display: flex;
		flex-direction: column;
		gap: var(--spacing-32);

		width: 100%;

		.nav-offcanvas-item {
			position: relative;

			font-size: clamp(2.5rem, 3.2vw, 3rem);
			line-height: clamp(3rem, 4vw, 4rem);
			text-transform: uppercase;

			height: clamp(3rem, 4vw, 4rem);

			a { display: inline-block; }

			@media screen and (max-width: 540px) {
				font-size: min(8vw, 2.5rem);
				line-height: min(9.6vw, 3rem);
				height: min(9.6vw, 3rem);
			}
		}
	}

	.cta-item {

		a { 
			display: flex;
			align-items: center;
			gap: var(--spacing-24);
			padding: var(--spacing-24) var(--spacing-48);

			font-size: clamp(1.125rem, 2vw, 1.5rem);
			line-height: clamp(2.25rem, 4vw, 3rem);

			background-color: var(--color-dark-faded);
			color: var(--color-white);
			border-radius: 4rem;

			span {
				gap: var(--spacing-24);
			}
		}
		
		svg {
			width: clamp(1.5625rem, 2.5vw, 2.5rem);
			height: auto;
		}
	}

	&-footer {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;

		gap: var(--spacing-24);
	}

	.social-links {
		display: flex;
		gap: var(--spacing-8) var(--spacing-64);
		flex-wrap: wrap;

		a {
			font-size: clamp(1rem, 1.25vw, 1.25rem);
			line-height: 2rem;
		}
	}

	@media screen and (max-width: 767px) {
		left: 0;
		
		width: calc(100% - 2*var(--spacing-96));
		padding: var(--spacing-96);
	}
}


.backdrop {
	visibility: hidden;

	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	
	width: 100%;
	height: 100%;
	
	background-color: var(--color-dark);
	opacity: .5;

	z-index: 200;
}




// =======================
//  HERO
// =======================

.hero {
	display: flex;
	justify-content: space-between;
	gap: var(--spacing-48);

	h1 {
		margin: 0;
		padding-top: var(--spacing-96);
		padding-bottom: var(--spacing-section-xs);
	}	

	@media screen and (max-width: 767px) {
		position: relative;

		flex-direction: column;
		justify-content: space-evenly;

		min-height: calc(100vh - var(--spacing-section-sm));

		h1 {
			padding-top: var(--spacing-section-xxl);
			padding-bottom: var(--spacing-96);
		}
	}
}

.hero-bottom-row {
	display: flex;
	gap: var(--spacing-64);
	align-items: center;

	margin-bottom: var(--spacing-64);
	
	@media screen and (max-width: 767px) {
		position: absolute;
		bottom: 0;	
	}
}

.hero-content {
	display: flex;
    flex-direction: column;
    justify-content: center;

	max-width: calc(50% - var(--spacing-24));
	height: calc(100vh - var(--spacing-section-sm));
	min-height: max(40vw, 40rem);
	max-height: 62.5vw; // aspect ration 16:10

	@media screen and (max-width: 1199px) {
		max-width: calc(60% - var(--spacing-24));
		max-height: 75vw; // aspect ration 4:3
	}
	
	@media screen and (max-width: 991px) {
		max-height: 80vw;
	}

	@media screen and (max-width: 767px) {
		max-width: 100%;
		height: auto;
		min-height: unset;
		max-height: unset;
	}
}

.hero-image {
	position: relative;
	z-index: 50;

	width: min(32.5vw, 42.75rem);
	display: flex;
	align-items: flex-end;
	margin-bottom: calc(-1 * var(--spacing-section-xs));
	margin-right: min(1.66vw, 2rem);

	img {
		width: 110%;
		height: auto;
	}

	@media screen and (max-width: 1199px) {
		margin-right: 0;
	}

	@media screen and (max-width: 767px) {
		position: relative;
        
        align-self: flex-end;
        
		right: calc(-1 * var(--page-margins));
		width: 80%;

		overflow: hidden;

		img {
    		position: relative;
    		left: calc(1.5* var(--spacing-64));
		}
	}
}

@keyframes cb-fx-rotating-360 {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}


.cta-badge {
	position: relative;
	width: clamp(8rem, 8vw, 10rem);
	height: clamp(8rem, 8vw, 10rem);

	flex: 0 0 clamp(8rem, 8vw, 10rem);
	display: flex;
	justify-content: center;
	align-items: center;

	&-text {
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		animation: cb-fx-rotating-360 15s infinite linear;

		img {
			width: 100%;
			height: 100%;
		}
	}

	&-media {
		width: 65%;
		height: 65%;
		border-radius: 100%;
		overflow: hidden;

		video, img {
			width: 100%;
			height: 100%;
		}
	}	

	@media screen and (max-width: 767px) {
		width: clamp(7rem, 20vw, 10rem);
		height: clamp(7rem, 20vw, 10rem);

		flex: 0 0 clamp(7rem, 20vw, 10rem);
	}
}

.occupation {
	font-size: clamp(1rem, 1.125vw, 1.25rem);
	line-height: clamp(1.6rem, 1.8vw, 2rem);
	letter-spacing: 0.04em;
	text-transform: uppercase;

	@media screen and (max-width: 767px) {
		position: absolute;
		width: 200%;
		rotate: -90deg;
		transform-origin: 0 0;
		top: -1.5rem;
		left: 2rem;

		font-size: clamp(0.9rem, 3.25vw, 1.25rem);
    	line-height: clamp(1.5rem, 5.2vw, 2rem);
	}
}

.location {
	font-size: clamp(1rem, 1.125vw, 1.25rem);
	line-height: clamp(1.6rem, 1.8vw, 2rem);
	letter-spacing: 0.04em;

	em {
		font-style: normal;
		color: var(--color-white);
	}
}



// =======================
//  HOME ABOUT
// =======================

.home-about {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: var(--spacing-48);
	align-items: end;

	padding-top: var(--spacing-section-md);
	padding-bottom: var(--spacing-section-lg);

	&-image {
		grid-column: 1 / span 4;

		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;

		border-radius: 1rem;
		overflow: hidden;

		img {
			object-fit: cover;
			min-width: 100%;
			min-height: 100%;
		}
	}

	&-content {
		grid-column: 6 / span 6;

		display: flex;
		flex-direction: column;
		gap: var(--spacing-64);

		padding-top: clamp(7.5rem, 12.5vw, 15rem);

		.btn-row {
			display: flex;
		}
	}

	p {
		margin: 0;

		font-size: clamp(1rem, 1.25vw, 1.5rem);
		line-height: clamp(2rem, 2.5vw, 3rem);
		letter-spacing: 0.02em;
	}

	@media screen and (max-width: 1024px) {
		&-image {
			grid-column: 1 / span 5;
		}

		&-content {
			grid-column: 7 / span 6;
		}
	}

	@media screen and (max-width: 767px) {
		align-items: start;
		padding-top: var(--spacing-section-lg);

		&-image {
			grid-row: 1;
			grid-column: 1 / span 9;
			height: auto;
		}

		&-content {
			grid-row: 2;
			grid-column: 1 / span 12;

			padding-top: var(--spacing-64);
		}
	}
}









// =======================
//  SELECTED WORKS
// =======================

.selected-works {
	padding-top:  var(--spacing-section-xs);
	padding-bottom:  var(--spacing-section-md);

	.btn-row {
		display: flex;
		justify-content: center;
		margin-top: var(--spacing-96);
	}
}

.selected-works-header {
	display: flex;
	justify-content: space-between;
	margin-bottom: var(--spacing-section-sm);

	.period {
		font-size: clamp(1rem, 1.04vw, 1.25rem);
		line-height: clamp(2rem, 2.08vw, 2.5rem);
	}

	@media screen and (max-width: 767px) {
		flex-direction: column-reverse;
		margin-bottom: var(--spacing-96);
	}

}

.portfolio-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: var(--spacing-section-sm) var(--spacing-96);

	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
		gap: var(--spacing-64);
	}
}

.portfolio-item {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-48);

	color: var(--color-gray-500);
	text-decoration: none;

	transition: opacity .5s .125s;

	

	.thumbnail {
		width: 100%;
		
		border-radius: 1rem;
		overflow: hidden;

		transition: border-radius .5s;
		
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

		
	&:hover, &:focus {
		color: var(--color-gray-500);

		.thumbnail {
			border-radius: 50%;
		}

	}
	&.no-hover {
		opacity: .25;
		transition: opacity .5s;
	}
	
	.text {
		display: flex;
		flex-direction: column;
		gap: var(--spacing-24);

		p {
			font-size: clamp(1rem, 1.04vw, 1.25rem);
			line-height: clamp(1.8rem, 1.875vw, 2.25rem);
			margin: 0;
			letter-spacing: 0.02em;
		}
	}
}




// =======================
//  SERVICES
// =======================

.services {
	padding-top: var(--spacing-section-lg);
	padding-bottom: var(--spacing-section-xl);
}

.services-header {
	margin-bottom: var(--spacing-96);
}

.services-list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: var(--spacing-96);

	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
		gap: var(--spacing-section-md);
	}
}

.service-card {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-32);

	&-headline {
		display: flex;
		align-items: center;
		gap: var(--spacing-24);

		.icon {
			width: clamp(1.5rem, 2.29vw, 2.75rem);
			height: clamp(1.5rem, 2.29vw, 2.75rem);

			img {
				width: 100%;
				height: auto;
			}
		}
	}

	&-content {
		display: flex;
		flex-direction: column;
		gap: var(--spacing-32);

		.perex {
			font-size: clamp(1.25rem, 1.25vw, 1.5rem);
		}

		p {
			margin: 0;

			font-size: clamp(1rem, 0.9375vw, 1.125rem);
			line-height: clamp(1.8rem, 1.875vw, 2.25rem);
		}

	}

}




// =======================
//  SOCIALS
// =======================

.follow-me-headline {

	padding-top: var(--spacing-24);
	padding-bottom: var(--spacing-24);

	background-color: var(--color-accent);
	color: var(--color-dark);

	overflow: hidden;
	
	&-wrap {
		display: flex;
		flex-wrap: nowrap;
	}

	&-item {
		display: flex;
		gap: var(--spacing-96);
		align-items: center;

		margin-right: var(--spacing-96);;

		font-size: clamp(1.5rem, 2.5vw, 3rem);
		line-height: clamp(2.5rem, 4.16vw, 5rem);
		text-transform: uppercase;

		.italic {
			font-family: 'KristolitItalic', serif;
			font-style: italic;
			text-transform: none;
			
			opacity: .5;
		}

		.dot {
			opacity: .5;
		}
	}
}

.social-link {
	position: relative;

	display: block;

	font-family: 'ClashDisplay', sans-serif;
	font-size: clamp(1.2rem, 1.66vw, 2rem);
	line-height: clamp(2.4rem, 3.33vw, 4rem);
	
	border-bottom: 1px solid var(--color-gray-150);
	color: var(--color-white);
	text-decoration: none;

	transition: color .35s ease-out;

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;

		transform: scaleY(0);

		transition: all .25s ease-out;

		background-color: var(--color-accent);
		z-index: 5;
	}

	svg {
		width: clamp(1.5rem, 2.083vw, 2.5rem);
		height: clamp(1.5rem, 2.083vw, 2.5rem);

		path {
			fill: var(--color-white);
			transition: fill .35s ease-out;
		}
	}

	.container {
		position: relative;
		z-index: 10;
	}

	&:hover, &:focus {
		font-weight: 500;
		color: var(--color-dark);

		&:before {
			transform: scaleY(1);
		}

		svg path {
			fill: var(--color-dark)
		}
	}

	
	&-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
	
		padding-top: var(--spacing-32);
		padding-bottom: var(--spacing-32);
	}

	
	
}







// =======================
//  REACH OUT
// =======================

.reach-out {
	display: grid;
	grid-template-columns: repeat(12, 1fr);

	padding-top: var(--spacing-section-sm);
}

.reach-out-content {
	grid-column: 1 / span 7;

	display: flex;
	flex-direction: column;
	gap: var(--spacing-48);

	padding-top: var(--spacing-section-md);
	padding-bottom: var(--spacing-section-lg);

	@media screen and (max-width: 767px) {
		grid-row: 1;
		grid-column: 1 / span 12;

		align-items: center;

		text-align: center;
	}
}

.reach-out-cta {
	display: flex;
	align-items: center;
	gap: var(--spacing-64);

	.buttons {
		display: flex;
		gap: var(--spacing-24);
	}
}

.reach-out-image {
	grid-column: 9 / span 4;

	display: flex;
	align-items: flex-end;

	img {
		display: block;
		object-fit: fill;

		max-width: 100%;
		max-height: 100%;
		height: auto;
	}

	@media screen and (max-width: 767px) {
		grid-row: 2;
		grid-column: 1 / span 12;

		justify-content: center;

		padding-right: 15%;

		img {
			max-width: 20rem;
			width: 40vw;	
		}
	}
}






// =======================
//  CONTENT PAGE
// =======================

.page-header {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}

.page-header-content {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-48);
	
	padding-top: var(--spacing-section-md);
	padding-bottom: var(--spacing-section-xs);
	
}





// =======================
//  ABOUT PAGE
// =======================

.about-geninfo {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: var(--spacing-48);

	padding-top: var(--spacing-section-md);
	padding-bottom: var(--spacing-section-md);

	@media screen and (max-width: 399px) {
		gap: 0;
	}
}

.about-geninfo-content {
	grid-column: 1 / span 5;

	display: flex;
	flex-direction: column;
	gap: var(--spacing-section-md);

	padding-bottom: var(--spacing-section-md);

	h3 {
		margin-bottom: var(--spacing-64);
	}

	p {
		margin-bottom: var(--spacing-48);

		font-size: clamp(1rem, 1.25vw, 1.5rem);
		line-height: clamp(2rem, 2.5vw, 3rem);
		letter-spacing: 0.02em;
	}

	@media screen and (max-width: 767px) {
		grid-column: 1 / span 12;
	}
}

.about-geninfo-image {
	grid-column: 7 / span 6;

	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;

	border-radius: 1rem;
	overflow: hidden;
	
	img {
		object-fit: cover;
		min-width: 100%;
		min-height: 100%;
	}
	
	@media screen and (max-width: 767px) {
		height: auto;
	}
}

.xp-row {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
	gap: var(--spacing-64) var(--spacing-section-sm);

	// @media screen and (max-width: 1024px) {
	// 	flex-direction: column;
	// 	gap: var(--spacing-48);
	// }
}

.xp-item {
	display: flex;
	align-items: center;
	gap: var(--spacing-24);

	@media screen and (max-width: 1024px) {
		display: grid;
		grid-template-columns: clamp(4.8rem, 6.66vw, 8rem) 1fr;
	}
	
	.number {
		font-size: clamp(2.4rem, 3.33vw, 4rem);
		line-height: min(3.6rem, 5vw, 6rem);
	
		color: var(--color-white);
	}

	.value {
		font-size: clamp(0.875rem, 1vw, 1rem);
		line-height: clamp(1rem, 1.25vw, 1.25rem);
		letter-spacing: 0.04em;
		text-transform: uppercase;
	}
}

.my-experience {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-section-md);

	padding-top: var(--spacing-section-lg);
	padding-bottom: var(--spacing-section-lg);
}

.my-experience-row {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: var(--spacing-48);

	@media screen and (max-width: 399px) {
		gap: var(--spacing-48) 0;
	}

	.tagline {
		grid-column: 1 / span 3;
	}

	.content {
		grid-column: 4 / span 9;

		display: grid;
		grid-template-columns: repeat(9, 1fr);
		gap: var(--spacing-48);

		
	}

	@media screen and (max-width: 767px) {
		.tagline {
			grid-row: 1;
			grid-column: 1 / span 12;
		}

		.content {
			grid-row: 2;
			grid-column: 1 / span 12;
		}
	}
	
	h3 {
		grid-column: 1 / span 8;

		margin-bottom: var(--spacing-64);
	}

	p {
		grid-column: 1 / span 8;

		margin-bottom: var(--spacing-48);

		font-size: clamp(1rem, 1.25vw, 1.5rem);
		line-height: clamp(2rem, 2.5vw, 3rem);
		letter-spacing: 0.02em;
	}
	
	.logo-grid {
		grid-column: 1 / span 9;

		display: grid;
		grid-template-columns: repeat(3, 1fr);
		justify-content: center;
		align-items: stretch;
		width: 100%;

		.item {
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 100%;
				height: auto;
				
				transition: transform .25s ease-out;
			}
			
			&:hover img {
				transform: scale(1.2)
			}

			&:nth-of-type(1) {
				border-bottom: 1px solid var(--color-gray-250);
			}
			&:nth-of-type(2) {
				border-left: 1px solid var(--color-gray-250);
				border-bottom: 1px solid var(--color-gray-250);
				border-right: 1px solid var(--color-gray-250);
			}
			&:nth-of-type(3) {
				border-bottom: 1px solid var(--color-gray-250);
			}
			&:nth-of-type(5) {
				border-left: 1px solid var(--color-gray-250);
				border-right: 1px solid var(--color-gray-250);
			}


			
		}

		@media screen and (max-width: 767px) {
			grid-template-columns: repeat(2, 1fr);

			.item {
				&:nth-of-type(2) {
					border-left: 1px solid var(--color-gray-250);
					border-bottom: 1px solid var(--color-gray-250);
					border-right: 0;
				}
				&:nth-of-type(3) {
					border-bottom: 1px solid var(--color-gray-250);
				}
				&:nth-of-type(4) {
					border-bottom: 1px solid var(--color-gray-250);
					border-left: 1px solid var(--color-gray-250);
				}
				&:nth-of-type(5) {
					border-left: 0;
					border-right: 0;
				}
				&:nth-of-type(6) {
					border-left: 1px solid var(--color-gray-250);
				}
			}

		}

		@media screen and (max-width: 420px) { 
			grid-template-columns: 1fr;

			
			.item {
				img {
					max-width: 12.5rem;
				}

				border: 0 !important;
				border-bottom: 1px solid var(--color-gray-250) !important;

				&:last-child {
					border-bottom: 0 !important;
				}
			}
		}
	}
}






// =======================
//  WORKS PAGE
// =======================

.works {
	padding-top: var(--spacing-64);
	padding-bottom: var(--spacing-section-md);

	.filter {
		display: flex;
		align-items: center;
		gap: var(--spacing-96);
		
		margin-bottom: var(--spacing-section-sm);

		&-tags {
			display: flex;
			flex-wrap: wrap;
			gap: var(--spacing-24);
		}
	}
}








// =======================
//  CONTACT PAGE
// =======================

.contact-page {
	padding-top: var(--spacing-section-lg);
	padding-bottom: var(--spacing-section-lg);

	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: 1fr 1fr 3fr;
	gap: var(--spacing-96) var(--spacing-48);
}

.contact-page-header {
	grid-column: 1 / span 7;
	grid-row: 1;
}

.contact-page-image {
	grid-column: 8 / span 5;
	grid-row: 1 / span 2;

	img {
		width: 100%;
		height: auto;
	}
}

.contact-page-form {
	grid-column: 1 / span 7;
	grid-row: 2 / span 2;
}

.contact-page-info {
	grid-column: 9 / span 4;
	grid-row: 3;

	display: flex;
	flex-direction: column;
	gap: var(--spacing-64);
}

@media screen and (max-width: 767px) {

	.contact-page {
		display: flex;
		flex-direction: column;
	}

	.contact-page-header {
		order: 1;
	}
	
	.contact-page-image {
		order: 2;
		align-self: center;

		img {
			max-width: 25rem;
			width: 100%;	
		}
	}
	
	.contact-page-info {
		order: 4;

		display: grid;
		grid-template-columns: 2fr 1fr;
		align-items: center;

		padding-top: var(--spacing-section-md);

		.cta-badge {
			grid-row: 1 / span 2;
			grid-column: 2;
		}
	}	
	
	.contact-page-form {
		order: 3;
	}
}

.info-card {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-16);

	.content {
		font-size: clamp(1rem, 1.25vw, 1.5rem);
		line-height: clamp(1.5rem, 1.875vw, 2.25rem);
		letter-spacing: 0.02em;

		color: var(--color-white);
	}
}

form {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-48);

	.title, .form-text label, .form-textarea label {
		display: block;

		margin-bottom: var(--spacing-16);

		font-size: clamp(1rem, 1.04vw, 1.25rem);
		line-height: clamp(2.4rem, 2.5vw, 3rem);

		color: var(--color-white);
	}

	.form-text input, .form-textarea textarea {
		width: 100%;
		margin: 0;
		padding: 0 0 var(--spacing-24);

		font-size: clamp(1.2rem, 1.66vw, 2rem);
		line-height: clamp(1.8rem, 2.5vw, 3rem);

		background: transparent;
		border: 0;
		border-bottom: 1px solid var(--color-gray-250);
		color: var(--color-white);

		transition: border-bottom .25s ease;

		&:hover {
			outline: 0;
			border-bottom: 1px solid var(--color-gray-500);
		}

		&:focus {
			outline: 0;
			border-bottom: 1px solid var(--color-white);
		}
	}

	.form-checkboxes, .form-radios {
		display: flex;
		flex-wrap: wrap;
		gap: var(--spacing-24);
	}

	.form-check, .form-radio {

		input {
			position: absolute;
			clip: rect(0, 0, 0, 0);
			pointer-events: none;
			
			& + label {
				display: inline-block;
				padding: var(--spacing-16) var(--spacing-32);

				color: var(--color-white);
				border: 1px solid var(--color-gray-250);
				border-radius: 2rem;

				&:hover {
					cursor: pointer;
				}
			}

			&:checked + label {
				color: var(--color-dark);
				background-color: var(--color-accent);
				border-color: var(--color-accent);
			}
		}

	}

	.form-slider {
		display: flex;
		justify-content: space-between;

		border: 1px solid var(--color-gray-250);
		border-radius: 2rem;

		margin-bottom: var(--spacing-16);

		&-point {
			input {
				position: absolute;
				clip: rect(0, 0, 0, 0);
				pointer-events: none;

				&:checked + label {
					background-color: var(--color-accent);
				}
			}

			label {
				display: block;
				width: 3rem;
				height: 3rem;

				background-color: var(--color-gray-250);
				border-radius: 2rem;

				transition: background-color .25s ease;
			}

			&:hover label {
				background-color: var(--color-accent);
				cursor: pointer;
			}
		}
	}

	.form-slider-range {
		display: flex;
		justify-content: space-between;
	}

	.form-slider-statuses {
		margin-top: var(--spacing-24);	
		margin-bottom: var(--spacing-48);	
	}

	.form-slider-status {
		display: none;
		
		&.show {
			display: flex;
			
			flex-direction: column;
			align-items: center;
			gap: var(--spacing-24);
	

			text-align: center;
		}

		&-price {
			font-size: clamp(1rem, 1.25vw, 1.5rem);
			line-height: clamp(1.25rem, 1.66vw, 2rem);

			color: var(--color-white);
		}

		&-description {
			line-height: clamp(1.25rem, 1.66vw, 2rem);

			padding-left: var(--spacing-64);
			padding-right: var(--spacing-64);
		}
	}

	.form-legalcheck {
		display: flex;
		align-items: center;
		gap: var(--spacing-24);

		input {
			appearance: none;
			
			width: clamp(1.5rem, 1.5vw, 2rem);
			height: clamp(1.5rem, 1.5vw, 2rem);

			
			background-color: var(--color-dark);
			border: 1px solid var(--color-gray-250);
			border-radius: 0.25rem;

			
			&:checked {
				background-color: var(--color-accent);
				border: 1px solid var(--color-accent);
			}
		}

		a {
			text-decoration: underline;
		}
	}

	button {
		justify-content: center;
	}

	.invalid-feedback {
		display: inline-block;
		
		margin-top: var(--spacing-16);
		padding: var(--spacing-16) var(--spacing-24);
		
		font-size: 0.875rem;
		
		color: var(--color-error);
		border: 1px solid var(--color-error-faded);
		border-radius: 2rem;
	}
}

.form-alerts {
	position: fixed;
	top: var(--spacing-64);
	left: var(--spacing-32);
	right: var(--spacing-32);

	display: flex;
	justify-content: center;

	pointer-events: none;

	z-index: 285;

	.alert {
		display: flex;
		align-items: center;
		gap: var(--spacing-32);

		padding: var(--spacing-24) var(--spacing-48);
		border-radius: 2rem;
		overflow: hidden;
		
		pointer-events: all;
		
		.text-wrap {
			overflow: hidden;
		}

		.close-btn-wrap {
			overflow: hidden;
			flex: 1 0 1.5rem;
		}

		strong {
			font-weight: 500;
		}

		.close-btn {
			display: flex;
			align-items: center;

			cursor: pointer;
		}

		&.hidden {
			display: none;
		}
	}

	.alert-success {
		position: relative;
		color: var(--color-dark);

		.alert-bg {
			position: absolute;
			top: 0; left: 0;
			width: 100%;
			height: 100%;
			
			background-color: var(--color-accent);
			border-radius: 2rem;
			
			z-index: -1;
		}
	}
}

.oc-progress-bar {
	background: var(--color-accent);
	height: 0.25rem;
}



// =======================
//  CASE STUDY
// =======================

.case-study-header {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--spacing-32);
	
	padding-top: var(--spacing-section-md);
	padding-bottom: var(--spacing-96);
}

.case-study-tag {
	padding: var(--spacing-16) var(--spacing-24);

	font-size: 0.875rem;
	line-height: 1.5rem;
	text-transform: uppercase;

	color: var(--color-white);

	border: 1px solid var(--color-gray-250);
	border-radius: 5rem;
}

.case-study-client-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	
	padding-top: var(--spacing-48);
	padding-bottom: var(--spacing-48);

}

.client-info {
	display: flex;
	gap: var(--spacing-32);
}

.label {
	font-size: clamp(0.875rem, 1vw, 1rem);
	line-height: clamp(1.2rem, 1.5vw, 1.5rem);
	letter-spacing: 0.04em;
	text-transform: uppercase;
}
.value {
	color: var(--color-white);
	font-size: clamp(1rem, 0.9375vw, 1.125rem);
	line-height: clamp(1rem, 1.25vw, 1.25rem);
}

.year {
	font-size: clamp(1rem, 0.9375vw, 1.125rem);
	line-height: clamp(1rem, 1.25vw, 1.25rem);
}
.case-study-image {
		
	&.parallax-wrap {
		border-radius: 1rem;
	}
	
	img {
		border-radius: 1rem;
		display: block;
		width: 100%;
		height: auto;
	}

	.caption {
		text-align: center;
	}
}

.case-study-huge-image {
	display: flex;
	flex-direction: column;

	border-radius: 1rem;
	overflow: hidden;

	img {
		display: block;
		
		width: 100%;
		height: auto;
	}
}


.case-study-next {
	position: relative;
	display: block;
	overflow: hidden;

	.content {
		position: relative;

		display: flex;
		flex-direction: column;
		align-items: center;
		gap: var(--spacing-16);

		margin-top: var(--spacing-section-xxl);
		margin-bottom: var(--spacing-section-xxl);
		padding-left: var(--page-margins);
		padding-right: var(--page-margins);

		z-index: 10;
	}

	.next-project-tag {
		padding: var(--spacing-16) var(--spacing-24);

		font-size: clamp(0.875rem, 1vw, 1rem);
		line-height: clamp(1rem, 1.25vw, 1.25rem);
		letter-spacing: 0.04em;
		text-transform: uppercase;

		color: var(--color-white);

		border: 1px solid var(--color-gray-250);
		border-radius: 5rem;

		.italic {
			font-family: 'KristolitItalic', serif;
			font-style: italic;
			text-transform: none;

			color: var(--color-accent);
		}
	}

	.next-project-name {
		font-size: clamp(1.5rem, 3.33vw, 4rem);
		line-height: clamp(1.875rem, 4.16vw, 5rem);
		text-transform: uppercase;
		text-align: center;
		letter-spacing: 0.02em;

		color: var(--color-white);
	}

	.image {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 5;

		display: flex;
		justify-content: center;
		align-items: center;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;

			background: linear-gradient(0deg, rgba(23,23,23,.8) 0%, rgba(23,23,23,1) 100%);
		}
	}
	
}

.text-content-block {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: var(--spacing-48);

	padding-top: var(--spacing-section-md);
	padding-bottom: var(--spacing-section-md);

	.left-col { grid-column: 1 / span 4; }
	.right-col { grid-column: 7 / span 6; }

	@media screen and (max-width: 1024px) {
		.left-col { grid-column: 1 / span 4; }
		.right-col { grid-column: 6 / span 7; }
	}
	@media screen and (max-width: 767px) {
		display: flex;
		flex-direction: column;
		gap: var(--spacing-96);
		
	}

	.general-info {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		gap: var(--spacing-48);

		.item {
			display: flex;
			flex-direction: column;
			gap: var(--spacing-8);
		}

		.values {
			display: flex;
			flex-direction: column;
		}

		.value {
			line-height: clamp(1.6rem, 1.66vw, 2rem);

			a {
				text-decoration: underline;
			}
		}

		@media screen and (max-width: 767px) {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
		}
		
	}

	h3 {
		margin-bottom: var(--spacing-64);
	}

	p {
		margin-bottom: var(--spacing-32);

		font-size: clamp(1rem, 1.04vw, 1.25rem);
		line-height: clamp(1.6rem, 1.66vw, 2rem);
		letter-spacing: 0.02em;
	}
}

.logo-box {
	display: flex;
	justify-content: center;
	align-items: center;

	aspect-ratio: 1.6;
	
	background-color: var(--color-white);
	border-radius: 1rem;
	
	img {
		width: 31.5%;
		height: auto;
	}


	&.bergdoktor {
		background-color: #FFF0EB;
	}
}

.visual-sequence {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-96);

}

.case-study-mockup {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: var(--spacing-24) var(--spacing-48);

	&.pt {
		padding-top: var(--spacing-section-md);
	}
	&.pb {
		padding-bottom: var(--spacing-section-md);
	}

	& > div {
		grid-column: 2 / span 10;

		@media screen and (max-width: 1024px) {
			grid-column: 1 / span 12;
		}
	}

	.caption {
		text-align: center;
	}

	.device {
		position: relative;

		video {
			display: block;
			width: 100%;
			height: auto;
		}

		.video-timeline {
			position: absolute;
			left: 0;
			bottom: 0;

			width: 100%;
			height: 0.25rem;

			background-color: var(--color-accent);
		}
	}

	.device-laptop {
		background: url('../images/case_studies/macbook_frame_01.png') no-repeat center top;
		background-size: contain;
		aspect-ratio: 1.6;

		.screen-frame {
			position: absolute;
			top: 3.37%;
			left: 9.32291667%;

			width: 81.40625%;
			height: auto;

			border-radius: 0.25rem;
			overflow: hidden;
		}
		
		@media only screen and (min-device-pixel-ratio: 1.5) {
			background: url('../images/case_studies/macbook_frame_01@2x.png') no-repeat center top;
		}
	}
	
	.device-tablet {
		background: url('../images/case_studies/tablet_frame_01.png') no-repeat center top;
		background-size: contain;
		aspect-ratio: 1.3095;

		.screen-frame {
			position: absolute;
			top: 3.75%;
			left: 3%;
			
			width: 94%;
			height: auto;
			
			border-radius: 1vw;
			overflow: hidden;
		}
		
		@media only screen and (min-device-pixel-ratio: 1.5) {
			background: url('../images/case_studies/tablet_frame_01@2x.png') no-repeat center top;
		}
	}
}

.color-pallete {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	gap: var(--spacing-8);
	
	@media screen and (max-width: 767px) {
		grid-template-columns: repeat(4, 1fr);
	}

	@media screen and (max-width: 420px) {
		grid-template-columns: repeat(2, 1fr);
	}

	.color {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		
		height: var(--spacing-section-xl);
		padding: var(--spacing-32);
		border-radius: 1rem;
		
		&.x-small { grid-column: span 1; }
		&.small { grid-column: span 2; }
		&.big { grid-column: span 4;}

		
		&.light-text { color: var(--color-white); }
		&.dark-text { color: var(--color-dark); }
		
		.title {
			font-size: clamp(1rem, 1.25vw, 1.5rem);
			line-height: clamp(1.25rem, 1.66vw, 2rem);
			letter-spacing: 0.02em;
		}
		.hex {
			opacity: .4;
		}
		
		@media screen and (max-width: 420px) {
			height: var(--spacing-section-md);

			&.x-small { grid-column: span 1; }
			&.small { grid-column: span 2; }
			&.big { grid-column: span 2;}
		}
	}
}

.gallery-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: var(--spacing-32);

	@media screen and (max-width: 767px) {
		grid-template-columns: repeat(1, 1fr);
	}

	.item {
		border-radius: 1rem;
		overflow: hidden;

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}




// =======================
//  FOOTER
// =======================

.footer {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: var(--spacing-48);
	align-items: center;

	padding-top: var(--spacing-96);
	padding-bottom: var(--spacing-96);

	.legal-links {
		grid-column: 1 / span 3;

		display: flex;
		gap: var(--spacing-32);
	}

	.local-time {
		grid-column: 4 / span 3;
		
		display: flex;
		gap: var(--spacing-32);

		a {
			color: var(--color-gray-500);

			&.active {
				color: var(--color-white);
			}
			&:hover, &:focus {
				color: var(--color-accent);
			}
		}
	}

	.copyright {
		grid-column: 7 / span 6;

		display: flex;
		gap: var(--spacing-64);
		justify-content: flex-end;
	}

	em {
		font-style: normal;
		color: var(--color-white);
	}

	@media screen and (max-width: 1024px) {
		.legal-links {
			grid-row: 1;
			grid-column: span 6;
		}
		.language-links {
			grid-row: 2;
			grid-column: span 6;
		}
		.copyright {
			grid-row: 1 / span 2;
			grid-column: span 6;
			
			
			flex-direction: column;
			align-items: end;
			gap: var(--spacing-48);
		}
	}

	@media screen and (max-width: 767px) {

		display: flex;
		flex-direction: column;
		gap: var(--spacing-96);
		text-align: center;
		
		.copyright {
			align-items: center;
		}

	}
}