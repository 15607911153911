:root {

    // =================================
    //  SIZING
    // =================================

    --title-size: min(4vw, 4em);
    
    @media screen and (max-width: 1700px) { --title-size: min(3.75vw, 3.75em); }
    @media screen and (max-width: 1399px) { --title-size: min(4.5vw, 4.5em); }
    @media screen and (max-width: 1024px) { --title-size: 6vw; }
    @media screen and (max-width: 767px) { --title-size: 7.5vw; }
    @media screen and (max-width: 540px) { --title-size: max(2rem, 8vw); }


    
    --border-radius: clamp(1em, 1.5vw, 1.5em);
    --border-radius-medium: calc(clamp(1em, 1.5vw, 1.5em)* 1.5);
    --border-radius-small: calc(clamp(1em, 1.5vw, 1.5em)* 0.5);
    
    @media screen and (max-width: 540px) {
        --border-radius-small: var(--border-radius);
        --border-radius-medium: calc(var(--border-radius)* 1.5);
    }
    
    



    // =================================
    //  SPACING
    // =================================

    // Spacing variables for general purposes
    //  - paddings, margins, spacing between elements
    --spacing-8: clamp(0.25rem, 0.42vw, 0.5rem);
    --spacing-16: clamp(0.5rem, 0.83vw, 1rem);
    --spacing-24: clamp(0.75rem, 1.25vw, 1.5rem);
    --spacing-32: clamp(1rem, 1.67vw, 2rem);
    --spacing-48: clamp(1.5rem, 2.5vw, 3rem);
    --spacing-64: clamp(2rem, 3.33vw, 4rem);
    --spacing-96: clamp(3rem, 5vw, 6rem);



    // Sections spacing variables
    //  - vertical spacing between big sections
    --spacing-section-xs: min(5vw, 5rem);
    --spacing-section-sm: min(7.5vw, 7.5rem);
    --spacing-section-md: min(10vw, 10rem);
    --spacing-section-lg: min(12.5vw, 12.5rem);
    --spacing-section-xl: min(15vw, 15rem);
    --spacing-section-xxl: min(25vw, 25rem);

    @media screen and (max-width: 1024px) {
        
    }

    @media screen and (max-width: 767px) {        
        --spacing-section-xs: max(2.5rem, 2.5vw);
        --spacing-section-sm: max(3.75rem, 3.75vw);
        --spacing-section-md: max(5rem, 5vw);
        --spacing-section-lg: max(6.25rem, 6.25vw);
        --spacing-section-xl: max(7.5rem, 7.5vw);
    }
    
    

    // Side margins of the whole page
    //  - distance of .container edges from browser edges
    --page-margins: min(6.67vw, 8em);
    
    @media screen and (max-width: 1024px) { --page-margins: min(8vw, 4em); }
    @media screen and (max-width: 767px) { --page-margins: clamp(1.5em, 6vw, 2.5em); }



    // Standard gap in flexes and grids
    --gap-padding: clamp(1em, 4vw, 2.5rem);



    

    // =================================
    //  COLORS
    // =================================

    --color-accent: #C4F240;
    --color-accent-darker: #94B830;
    
    --color-dark: #171717;
    --color-dark-faded: #282D1B;
    
    --color-white: #FFFFFF;
    
    --color-error: #FF767B;
    --color-error-faded: #452A2B;
    
    --color-gray-500: #8B8B8B;
    --color-gray-250: #515151;
    --color-gray-150: #3A3A3A;
    --color-gray-50: #232323;
    
    
    


    // =================================
    //  ANIMATIONS
    // =================================

    --animation-primary: .5s cubic-bezier(.3, 1, .3, 1);
    --animation-nav: .5s cubic-bezier(.6, 0, .4, 1);
    --animation-smooth: .5s cubic-bezier(.7, 0, .3, 1);
    
}