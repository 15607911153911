@font-face {
	font-family: 'KristolitItalic';
	src: url('../fonts/kristolit-italic.eot');
	src: url('../fonts/kristolit-italic.eot?#iefix') format('embedded-opentype'),
             url('../fonts/kristolit-italic.woff2') format('woff2'),
	     url('../fonts/kristolit-italic.woff') format('woff'),
	     url('../fonts/kristolit-italic.ttf') format('truetype'),
	     url('../fonts/kristolit-italic.svg#youworkforthem') format('svg');
	font-weight: normal;
	font-style: italic;
}





@font-face {
    font-family: 'ClashDisplay';
    src: url('../fonts/ClashDisplay-Regular.woff2') format('woff2'),
        url('../fonts/ClashDisplay-Regular.woff') format('woff'),
        url('../fonts/ClashDisplay-Regular.ttf') format('truetype');
        font-weight: 400;
        font-display: swap;
        font-style: normal;
}





@font-face {
    font-family: 'ClashGrotesk';
    src: url('../fonts/ClashGrotesk-Light.woff2') format('woff2'),
        url('../fonts/ClashGrotesk-Light.woff') format('woff'),
        url('../fonts/ClashGrotesk-Light.ttf') format('truetype');
        font-weight: 300;
        font-display: swap;
        font-style: normal;
}

@font-face {
    font-family: 'ClashGrotesk';
    src: url('../fonts/ClashGrotesk-Regular.woff2') format('woff2'),
        url('../fonts/ClashGrotesk-Regular.woff') format('woff'),
        url('../fonts/ClashGrotesk-Regular.ttf') format('truetype');
        font-weight: 400;
        font-display: swap;
        font-style: normal;
}

@font-face {
    font-family: 'ClashGrotesk';
    src: url('../fonts/ClashGrotesk-Medium.woff2') format('woff2'),
        url('../fonts/ClashGrotesk-Medium.woff') format('woff'),
        url('../fonts/ClashGrotesk-Medium.ttf') format('truetype');
        font-weight: 500;
        font-display: swap;
        font-style: normal;
}
